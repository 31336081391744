<template>
  <div v-if="getPercorso">
      <header>
        <h1 class="header--title"> <router-link :to="{ name: 'EditorPercorsi'} ">Percorsi</router-link> / {{ getPercorso.titolo }}</h1>
       
        <div class="header--right">
            <router-link :to="{ name: 'EditorQuiz', params: { percorsoId: getPercorso.id} }" class="button button--small">Quiz</router-link>
            <router-link :to="{ name: 'EditorLezioni', params: { percorsoId: getPercorso.id} }" class="button button--small button--active">Lezioni</router-link>
        </div>
      </header>

      <div v-if="lezioni && lezioni.length > 0" >
        <table class="table">

                  <tr class="table--header">
                        <th class="cell">
                          Ordine
                        </th>
                        <th class="cell">
                          Titolo
                        </th>
                        <th class="cell">
                          Stato
                        </th>
                        <th class="cell">
                          Risorse
                        </th>
                        <th class="cell">
                          Azioni
                        </th>
                  </tr>

                  <tr v-for="lezione in lezioni" :key="lezione.id" class="row">
                      <td class="cell cell--top">{{ lezione.ordine }}</td>
                      <td class="cell cell--top"><strong>{{ lezione.titolo }}</strong></td>
                      <td class="cell cell--top">
                        <span class="badge badge--success" v-if="lezione.published">Pubblicata</span>
                        <span class="badge badge--lightgray" v-else>Bozza</span>
                      </td>
                      <td class="cell cell--top">
                          <template v-if="lezione.risorse.length > 0">
                            <div v-for="risorsa in lezione.risorse" :key="risorsa.id">
                                <ModificaRisorsa :risorsa="risorsa" :lezioneId="lezione.id"/>
                            </div>
                            <NuovaRisorsa :lezioneId="lezione.id" :percorsoId="getPercorso.id" />
                          </template>
                          <template v-else>
                          <NuovaRisorsa :lezioneId="lezione.id" :percorsoId="getPercorso.id" />
                          </template>
                        </td>
                      <td class="cell cell--top"><ModificaLezione :lezione="lezione"/></td>
                  </tr>
        </table>
      </div>
      <div v-else>
          <p>Nessuna lezione per questo percorso</p>
      </div>
      <NuovaLezione :percorsoId="getPercorso.id"/>

  </div>
  <div v-else>
      <UiPreloader/>
  </div>
</template>

<script>
// @ is an alias to /src
//import draggable from 'vuedraggable'
import NuovaLezione from '@/components/editor/NuovaLezione.vue'
import NuovaRisorsa from '@/components/editor/NuovaRisorsa.vue'
import ModificaLezione from '@/components/editor/ModificaLezione.vue'
import ModificaRisorsa from '@/components/editor/ModificaRisorsa.vue'
import ElencoRisorse from '@/components/editor/ElencoRisorse.vue'

import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'EditorLezioni',

  data: () => {
    return {
     
    }
  },
  
  components: {
    NuovaLezione,
    NuovaRisorsa,
    ModificaLezione,
    ModificaRisorsa,
    ElencoRisorse,
    //draggable
  },

  watch: {
      '$route.params': {
          handler(newValue) {
              const { percorsoId } = newValue
              this.$store.dispatch('percorsi/fetchPercorso', {
                id: percorsoId
              })
          },
          immediate: true,
      }
  },

  computed: {
    ...mapGetters([
      'isLogged',
      'isAdmin',
      'isEducatore',
      'isInsegnante',
      'isClasse',
      'user'
    ]),

    ...mapGetters('percorsi',[
        'getPercorso'
     ]),

    ...mapGetters('lezioni',[
        'getLezioni',
        'getLezioniByPercorso'
     ]),

    lezioni: function() {
      return this.getPercorso.lezioni.filter(lezione => lezione.percorso != null)
    }
  },

  methods: {
    ...mapActions('percorsi',[
        'fetchPercorso',
    ]),

    ...mapActions('lezioni',[
        'fetchLezioni',
    ]),

  },
  /*
   beforeMount() {
    this.$store.dispatch('lezioni/fetchLezioni')
  },
  */


}
</script>
