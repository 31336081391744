<template>
    <span>
    <button class="button button--outline button--extrasmall button--withicon" :class="{'button--success' : risorsa.published }" @click="offcanvasShow = true">
    <span v-if="risorsa.tipo == 'allegato'"><svg class="icon" width="16" height="16"><use xlink:href="#attachment" /></svg></span> <span v-if="risorsa.tipo == 'video'"><svg class="icon" width="16" height="16"><use xlink:href="#video" /></svg></span>
     {{ risorsa.nome }}
    </button>
    <UiOffCanvas :offcanvas="offcanvasShow" :offcanvasShow.sync="offcanvasShow">
        <SuccessAlert/>
        <template v-slot:header>
            <h4>Risorsa {{ risorsa.nome }} </h4>
        </template>

        <template v-slot:form>
            <template v-if="!loading">
                <ErrorsAlert />
                <div class="form">

                    <div class="field-group flex">
                        <div class="field col col-12">
                        <label>Titolo risorsa</label>
                        <input type="text" v-model="nome" ref="nome">
                        </div>
                    </div>

                    <div class="iframe-container" v-if="risorsa.tipo == 'video' && risorsa.link">
                        <div class="iframe-container-header">{{ risorsa.nome }} </div>
                        <iframe frameborder="0" id="vid" class="iframevideo" allowfullscreen
                                :src="getId(risorsa.link)">
                        </iframe>                             
                    </div>

                    <div class="allegato-container" v-if="risorsa.tipo == 'allegato' && risorsa.link">
                        <a :href="risorsa.link" target="_blank">{{risorsa.link}}</a>                         
                    </div>

                    <div class="field-group flex" v-if="risorsa.tipo == 'video'">
                        <div class="field col col-12">
                            <label>Link video</label>
                            <input type="text" v-model="risorsa.link">
                        </div>
                    </div>                         

                    <div class="field-group flex">
                        <div class="field col col-12">
                        <label>Descrizione</label>
                        <vue-editor v-model="descrizione" :editorToolbar="customToolbar"></vue-editor>
                        </div>
                    </div>


                    <div class="field-group flex">
                        <div class="field col col-6">
                            <label>Ordine</label>
                            <input type="number" v-model="ordine">
                        </div>

                        <div class="field col col-6">
                            <label>Pubblicata</label>
                            <label class="switch">
                                <input type="checkbox" v-model="published">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>

                    <div class="form--action">
                        <button class="button--delete" @click.prevent="modalShow = true" :disabled="disableSaveButton">Elimina risorsa</button>
                        <button class="button button--small m-l-auto" @click.prevent="aggiornaRisorsa(risorsa)" :disabled="disableSaveButton">Salva</button>
                    </div>
                </div>
            </template>
            <template v-else>
                <UiPreloader/>
            </template>

        </template>
     </UiOffCanvas>

     <UiModal :modal="modalShow" :modalShow.sync="modalShow">
            <template v-slot:header>
                <h4 class="modal--title">Sicuri di voler eliminare {{ risorsa.nome }}?</h4>
            </template>

            <template v-slot:form>
                <div class="form">
                    <template v-if="!loading_elimina">
                    <ErrorsAlert />
                    <p>Digita <strong>ELIMINA</strong> nel campo sottostante per eliminare <strong>{{ risorsa.nome }}</strong>.</p>
                    <div class="field-group flex">
                        <div class="field col col-12">
                            <input type="text" v-model="confermaEliminazione">
                        </div>
                    </div>
                    <div class="field-group flex">
                    <button class="button button--annulla" @click="modalShow = false">Annulla</button>     
                    <button class="button button--conferma" @click.prevent="eliminaRisorsa(risorsa)" :disabled="confermaEliminazione != 'ELIMINA'">Conferma ed elimina</button>
                    </div>
                    </template>
                    <template v-else>
                        <UiPreloader/>
                    </template>
                </div>
            </template>
     </UiModal>

    </span>
</template>
<script>

import SuccessAlert from '@/components/SuccessAlert.vue'
import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { getIdFromUrl } from 'vue-youtube'

import { VueEditor } from "vue2-editor";

import { mapGetters, mapState, mapActions } from 'vuex'
import router from '@/router';

export default {
    name: 'ModificaRisorsa',

    data() {
        return {
            id: this.risorsa.id,
            loading: false,
            loading_elimina: false,
            disableSaveButton: false,
            offcanvasShow: false,
            nome: this.risorsa.nome,
            descrizione: this.risorsa.descrizione,
            tipo: this.risorsa.tipo,
            link: this.risorsa.link,
            published: this.risorsa.published,
            ordine: this.risorsa.ordine,
            lezione_id: this.lezioneId,
            modalShow: false,
            confermaEliminazione: '',
            customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]]
        }
    },
    
    props: {
        risorsa: Object,
        lezioneId: String
    },

    components: {
        VueEditor,
        ErrorsAlert,
        SuccessAlert
    },

    computed: {
      
    },

    methods: {
        ...mapActions('risorse',[
            'fetchRisorse',
            'updateRisorsa',
            'deleteRisorsa'
        ]),

        ...mapActions('percorsi',[
            'fetchPercorso'
        ]),

         ...mapActions('lezioni',[
            'fetchLezioni'
        ]),

        getId (link) {
            return 'https://www.youtube-nocookie.com/embed/'+getIdFromUrl(link)+'?=showinfo=0&modestbranding=1&autohide=2&showsearch=0&cc_load_policiy=3&rel=0';
        },

        aggiornaRisorsa () {
            this.loading = true
            this.disableSaveButton = true
            this.$store.state.success = null
            this.updateRisorsa({
                id:  this.id,
                nome: this.nome,
                tipo: this.tipo,
                published: this.published,
                lezione_id: this.lezione_id,
                link: this.link,
                descrizione: this.descrizione,
                ordine: this.ordine
            }).then((response) => {
                this.disableSaveButton = false
                this.$store.dispatch('risorse/fetchRisorse')
                if(this.$route.params.percorsoId) {
                    this.$store.dispatch('percorsi/fetchPercorso', {
                        id: this.$route.params.percorsoId
                    })
                }else{
                        this.$store.dispatch('lezioni/fetchLezioni')
                    }
                this.loading = false
                this.offcanvasShow = false
            }).catch(e => {
                this.loading = false
                this.$store.state.success = null
                this.$store.state.errors = e.response.data;
                this.disableSaveButton = false
            })
        },

        eliminaRisorsa (risorsa) {
            this.loading_elimina = true
            this.disableSaveButton = true
            this.deleteRisorsa(risorsa).then((response) => {
                this.offcanvasShow = false
                this.loading_elimina = false
                this.disableSaveButton = false
                if(this.$route.params.percorsoId) {
                    this.$store.dispatch('percorsi/fetchPercorso', {
                        id: this.$route.params.percorsoId
                    })
                } else{
                        this.$store.dispatch('lezioni/fetchLezioni')
                    }
            }).catch(error => {
                console.log('Errore:'+error)
            })
        }


    }
}
</script>