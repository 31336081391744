<template>
    <span>
    <button class="button button--extrasmall" @click="offcanvasShow = true">{{ lezione.risorse.length }} Risorse</button>
    <UiOffCanvas :offcanvas="offcanvasShow" :offcanvasShow.sync="offcanvasShow">
        <SuccessAlert/>
        <template v-slot:header>
            <h4>Risorse {{ lezione.titolo }} </h4>
        </template>

        <template v-slot:form>
            <template v-if="!loading">
                <ErrorsAlert />
                <div class="form">

                    <div v-for="risorsa in lezione.risorse" :key="risorsa.id">
                        <div class="iframe-container" v-if="risorsa.tipo == 'video' && risorsa.link">
                            <div class="iframe-container-header">{{ risorsa.nome }} </div>
                            <iframe frameborder="0" id="vid" class="iframevideo" allowfullscreen
                                    :src="getId(risorsa.link)">
                            </iframe>                             
                        </div>
                    </div>

                    <div class="form--action">
                        <button class="button--delete" @click.prevent="modalShow = true" :disabled="disableSaveButton">Elimina lezione</button>
                        <button class="button button--small m-l-auto" @click.prevent="aggiornaLezione(lezione)" :disabled="disableSaveButton">Salva</button>
                    </div>
                </div>
            </template>
            <template v-else>
                <UiPreloader/>
            </template>

        </template>

        <template v-slot:footer>
           
        </template>
     </UiOffCanvas>

     <UiModal :modal="modalShow" :modalShow.sync="modalShow">
            <template v-slot:header>
                <h4 class="modal--title">Sicuri di voler eliminare {{ lezione.titolo }}?</h4>
            </template>

            <template v-slot:form>
                <div class="form">
                    <template v-if="!loading">
                    <ErrorsAlert />
                    <p>Digita <strong>ELIMINA</strong> nel campo sottostante per eliminare <strong>{{ lezione.titolo }}</strong>. <br/>Verranno eliminate tutte le risorse associate</p>
                    <div class="field-group flex">
                        <div class="field col col-12">
                            <input type="text" v-model="confermaEliminazione">
                        </div>
                    </div>
                    <div class="field-group flex">
                    <button class="button button--annulla" @click="modalShow = false">Annulla</button>     
                    <button class="button button--conferma" @click.prevent="eliminaLezione(lezione)" :disabled="confermaEliminazione != 'ELIMINA'">Conferma ed elimina</button>
                    </div>
                    </template>
                    <template v-else>
                        <UiPreloader/>
                    </template>
                </div>
            </template>
     </UiModal>

    </span>
</template>
<script>

import SuccessAlert from '@/components/SuccessAlert.vue'
import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { getIdFromUrl } from 'vue-youtube'

import { VueEditor } from "vue2-editor";

import { mapGetters, mapState, mapActions } from 'vuex'

export default {
    name: 'ModificaLezione',

    data() {
        return {
            id: '',
            loading: false,
            disableSaveButton: false,
            offcanvasShow: false,
            titolo: this.lezione.titolo,
            testo: this.lezione.testo,
            ordine: this.lezione.ordine,
            percorso_id: this.lezione.percorso,
            modalShow: false,
            confermaEliminazione: '',
            customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]]
        }
    },
    
    props: {
        lezione: Object
    },

    components: {
        VueEditor,
        ErrorsAlert,
        SuccessAlert
    },

    computed: {
        player() {
            return this.$refs.youtube.player
        }
    },

    methods: {
        ...mapActions('lezioni',[
            'updateLezione',
            'deleteLezione'
        ]),

        ...mapActions('percorsi',[
            'fetchPercorso'
        ]),

         playVideo() {
            this.player.playVideo()
            },
            playing() {
            console.log('\o/ we are watching!!!')
        },

        getId (link) {
            return 'https://www.youtube-nocookie.com/embed/'+getIdFromUrl(link)+'?=showinfo=0&modestbranding=1&autohide=2&showsearch=0&cc_load_policiy=3&rel=0';
        },

        aggiornaLezione () {
            this.loading = true
            this.disableSaveButton = true
            this.$store.state.success = null
            this.updateLezione({
                id:  this.lezione.id,
                titolo: this.titolo,
                percorso_id: this.percorso_id,
                testo: this.testo,
                ordine: this.ordine
            }).then((response) => {
                this.loading = false
                this.offcanvasShow = false
                this.disableSaveButton = false
                this.$store.dispatch('percorsi/fetchPercorso', {
                    id: this.percorso_id
                })
            }).catch(e => {
                this.loading = false
                this.$store.state.success = null
                this.$store.state.errors = e.response.data;
                this.disableSaveButton = false
            })
        },

        eliminaLezione (lezione) {
            this.loading = true
            this.disableSaveButton = true
            this.deleteLezione(lezione).then((response) => {
                this.offcanvasShow = false
                this.loading = false
                this.disableSaveButton = false
                this.$store.dispatch('istituti/fetchIstituti')
            }).catch(error => {
                console.log('Errore:'+error)
            })
        }


    }
}
</script>