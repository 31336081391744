<template>
    <span v-if="lezioneId">
        <button class="button button--icon" @click="modalShow = true">
            <svg class="icon" width="10" height="10"><use xlink:href="#add" /></svg>
        </button>
        <UiModal :modal="modalShow" :modalShow.sync="modalShow">
                    <template v-slot:header>
                        <h4 class="modal--title">Aggiungi risorsa alla lezione</h4>
                    </template>

                    <template v-slot:form>
                        <template v-if="!loading">
                        <ErrorsAlert />
                        <div class="form">
                            <div class="field-group flex">
                                <div class="field col col-12">
                                <label>Titolo risorsa</label>
                                <input type="text" v-model="nome" ref="nome">
                                </div>
                            </div>

                            <div class="field-group flex">
                                <div class="field col col-12">
                                <label>Tipo risorsa <span class="required">*</span></label>
                                <multiselect v-model="tipo"
                                    :options="tipoRisorse"
                                    :searchable="false"
                                    :allow-empty="false"
                                    :show-labels="true"
                                    placeholder="Scegli tipo risorsa"></multiselect>
                                </div>
                             </div>

                             <div class="field-group flex" v-if="tipo == 'allegato'">
                                <div class="field col col-12">
                                    <label>File</label>
                                    <input type="file" @change="handleFileChange($event)">
                                </div>
                            </div>

                            <div class="field-group flex" v-if="tipo == 'video'">
                                <div class="field col col-12">
                                    <label>Link video</label>
                                    <input type="text" v-model="link">
                                </div>
                            </div>                         

                            <div class="field-group flex">
                                <div class="field col col-12">
                                <label>Descrizione</label>
                                <vue-editor v-model="descrizione" :editorToolbar="customToolbar"></vue-editor>
                                </div>
                            </div>

                             <div class="field-group flex">
                                <div class="field col col-6">
                                    <label>Ordine</label>
                                    <input type="number" v-model="ordine">
                                </div>

                                <div class="field col col-6">
                                    <label>Pubblicata</label>
                                    <label class="switch">
                                        <input type="checkbox" v-model="published">
                                        <span class="slider round"></span>
                                    </label>
                                    </div>

                            </div>


                            <div class="form--action">
                                <button class="button button--annulla" @click="modalShow = false">Annulla</button>
                                <button class="button button--conferma" @click.prevent="createRisorsa">Salva</button>     
                            </div>   

                        </div>
                        </template>
                        <template v-else>
                        <UiPreloader/>
                        </template>
                </template>
                    
        </UiModal>
    </span>
</template>
<script>

import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { VueEditor } from "vue2-editor";

import { mapActions } from 'vuex'

export default {
    name: 'NuovaLezione',

    data() {
        return {
            nome: '',
            loading: false,
            tipoRisorse: [
                'video', 'allegato'
            ],
            descrizione: '',
            tipo: '',
            published: false,
            filesSelected: '',
            video: '',
            link: '',
            ordine: '999',
            modalShow: false,
            customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]]
        }
    },

    props: {
        lezioneId: String,
        percorsoId: String
    },

    watch: {
    // autofocus sull'input
    modalShow(val){
        if (val == true){
            this.$nextTick(() => {
                this.$refs.nome.focus()
            })
        }
      }
    },

    components: {
        VueEditor,
        ErrorsAlert
    },

    methods: {

        ...mapActions('risorse',[
            'addRisorsa',
            'addVideoLink'
        ]),

        ...mapActions('lezioni',[
            'addLezione',
            'fetchLezioni'
        ]),

        ...mapActions('percorsi',[
            'fetchPercorso'
        ]),

        handleFileChange(evt) {
            this.filesSelected = evt.target.files;
        },

        createRisorsa () {
            this.loading = true
            let file = this.filesSelected[0];
            if(file) {
                let risorsa = new FormData();
                risorsa.append('nome', this.nome)
                risorsa.append('tipo', this.tipo)
                risorsa.append('descrizione', this.descrizione)
                risorsa.append('ordine', this.ordine)
                risorsa.append('published', JSON.stringify(this.published))
                //risorsa.append('video', this.video)
                risorsa.append('lezione_id', this.lezioneId)
                risorsa.append('allegato', file)

                this.addRisorsa(risorsa).then((response) => {
                    this.nome = ''
                    this.tipo = ''
                    this.descrizione = ''
                    this.published = false
                    this.$store.dispatch('risorse/fetchRisorse')
                    if(this.percorsoId) {
                        this.$store.dispatch('percorsi/fetchPercorso', {
                            id: this.percorsoId
                        })
                    }else{
                        this.$store.dispatch('lezioni/fetchLezioni')
                    }
                    this.modalShow = false
                    this.loading = false
                    }).catch(e => {
                        this.$store.state.success = null;
                        this.$store.state.errors = e.response.data;
                        this.loading = false
                    })
                    
            }
            else {
                this.addVideoLink({
                    'nome' : this.nome,
                    'tipo' : this.tipo,
                    'descrizione' : this.descrizione,
                    'ordine' : this.ordine,
                    'published': this.published,
                    'link': this.link,
                    'lezione_id': this.lezioneId
                }).then((response) => {
                this.nome = ''
                this.tipo = ''
                this.descrizione = ''
                
                this.$store.dispatch('risorse/fetchRisorse')
                if(this.percorsoId) {
                        this.$store.dispatch('percorsi/fetchPercorso', {
                            id: this.percorsoId
                        })
                } else{
                        this.$store.dispatch('lezioni/fetchLezioni')
                }
                this.modalShow = false
                this.loading = false
                }).catch(e => {
                        this.loading = false
                        this.$store.state.success = null;
                        this.$store.state.errors = e.response.data;
                })

            }
            
        }       

    }
}
</script>