<template>
    <span>
    <button class="button button--icon" @click="offcanvasShow = true"><svg class="icon" width="22" height="22"><use xlink:href="#edit" /></svg></button>
    <UiOffCanvas :offcanvas="offcanvasShow" :offcanvasShow.sync="offcanvasShow">

        <SuccessAlert/>
        <template v-slot:header>
            <h4>Modifica {{ lezione.titolo }} </h4>
        </template>

        <template v-slot:form>
            <template v-if="!loading">
                <ErrorsAlert />
                <div class="form">
                    <div class="field-group flex">
                        <div class="field col col-12">
                            <label>Titolo</label>
                            <input type="text" v-model="titolo">
                        </div>
                    </div>

                    <div class="field-group flex">
                        <div class="field col col-12">
                            <label>Testo</label>
                            <vue-editor v-model="testo" :editorToolbar="customToolbar"></vue-editor>
                        </div>
                    </div>
                    
                    <div class="field-group flex">
                        <div class="field col col-6">
                            <label>Ordine</label>
                            <input type="number" v-model="ordine">
                        </div>

                        <div class="field col col-3">
                        <label>Pubblicata</label>
                        <label class="switch">
                            <input type="checkbox" v-model="published">
                            <span class="slider round"></span>
                        </label>
                        </div>

                        <div class="field col col-3">
                        <label>Visibile alla classe</label>
                        <label class="switch">
                            <input type="checkbox" v-model="visibile_classe">
                            <span class="slider round"></span>
                        </label>
                        </div>

                    </div>

                    <div class="form--action">
                        <button class="button--delete" @click.prevent="modalShow = true" :disabled="disableSaveButton">Elimina lezione</button>
                        <button class="button button--small m-l-auto" @click.prevent="aggiornaLezione(lezione)" :disabled="disableSaveButton">Salva</button>
                    </div>
                </div>
            </template>
            <template v-else>
                <UiPreloader/>
            </template>

        </template>

        <template v-slot:footer>
           
        </template>
     </UiOffCanvas>

     <UiModal :modal="modalShow" :modalShow.sync="modalShow">
            <template v-slot:header>
                <h4 class="modal--title">Sicuri di voler eliminare {{ lezione.titolo }}?</h4>
            </template>

            <template v-slot:form>
                    <template v-if="!loading">
                        <div class="form">
                        <ErrorsAlert />
                        <p>Digita <strong>ELIMINA</strong> nel campo sottostante per eliminare <strong>{{ lezione.titolo }}</strong>. <br/>Verranno eliminate tutte le risorse associate</p>
                            <div class="field-group flex">
                                <div class="field col col-12">
                                    <input type="text" v-model="confermaEliminazione">
                                </div>
                            </div>
                            <div class="form--action">
                                <button class="button button--annulla" @click="modalShow = false">Annulla</button>     
                                <button class="button button--conferma" @click.prevent="eliminaLezione(lezione)" :disabled="confermaEliminazione != 'ELIMINA'">Conferma ed elimina</button>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <UiPreloader/>
                    </template>
            </template>
     </UiModal>

    </span>
</template>
<script>

import SuccessAlert from '@/components/SuccessAlert.vue'
import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { VueEditor } from "vue2-editor";

import { mapGetters, mapState, mapActions } from 'vuex'

export default {
    name: 'ModificaLezione',

    data() {
        return {
            id: '',
            loading: false,
            disableSaveButton: false,
            offcanvasShow: false,
            titolo: this.lezione.titolo,
            testo: this.lezione.testo,
            published: this.lezione.published,
            ordine: this.lezione.ordine,
            percorso_id: this.lezione.percorso,
            visibile_classe: this.lezione.visibile_classe,
            modalShow: false,
            confermaEliminazione: '',
            customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]]
        }
    },
    props: {
        lezione: Object
    },

    components: {
        VueEditor,
        ErrorsAlert,
        SuccessAlert
    },

    methods: {
        ...mapActions('lezioni',[
            'updateLezione',
            'deleteLezione'
        ]),

        ...mapActions('percorsi',[
            'fetchPercorso'
        ]),

        aggiornaLezione () {

            this.loading = true
            this.disableSaveButton = true
            this.$store.state.success = null
            this.updateLezione({
                id:  this.lezione.id,
                titolo: this.titolo,
                published: this.published,
                visibile_classe: this.visibile_classe,
                percorso_id: this.percorso_id,
                testo: this.testo,
                ordine: this.ordine
            }).then((response) => {
                this.loading = false
                this.offcanvasShow = false
                this.disableSaveButton = false
                this.$store.dispatch('percorsi/fetchPercorso', {
                    id: this.percorso_id
                })
            }).catch(e => {
                this.loading = false
                this.$store.state.success = null
                this.$store.state.errors = e.response.data;
                this.disableSaveButton = false
            })
        },

        eliminaLezione (lezione) {
            this.loading = true
            this.disableSaveButton = true
            this.deleteLezione(lezione).then((response) => {
                this.offcanvasShow = false
                this.loading = false
                this.disableSaveButton = false
                this.$store.dispatch('percorsi/fetchPercorso')
            }).catch(error => {
                console.log('Errore:'+error)
            })
        },

    }
}
</script>